<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Products</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-4"
          v-model="searchFilters.keyword"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Products')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear()"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="ml-1 mr-1" icon v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="searchFilters.isEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-text-field label="Serial Number" v-model="searchFilters.serialNumber" clearable @input="onSubmit" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="ml-1 mr-1" icon v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
      <v-btn
        v-if="$privilege.hasPrivilege('PRODUCT_CREATE')"
        class="ml-1 mr-1"
        fab
        small
        :to="{ name: 'product', params: { id: 0 } }"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
      >
        <template v-slot:item.productKey="{ item }">
          <router-link :to="{ name: 'product', params: { id: item.id } }">
            {{ item.productKey }}
          </router-link>
        </template>
        <template v-slot:item.effectiveDate="{ item }">
          <span :inner-html.prop="item | showActive"></span>
        </template>
        <template v-slot:item.updatedDate="{ item }">
          {{ item.updatedDate | formatDateFromNow }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Products",
  metaInfo: {
    title: "Products"
  },
  data() {
    return {
      searchFilters: {
        keyword: "",
        serialNumber: "",
        isEffective: "All"
      },

      loading: false,
      items: [],
      nameFilter: "",
      headers: [],
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      },

      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    initializeSearchFilter() {
      this.searchFilters = {
        keyword: "",
        serialNumber: "",
        isEffective: "All"
      };
    },
    onClear() {
      this.initializeSearchFilter();
      this.getData();
    },

    onClearSearch() {
      this.initializeSearchFilter();
      this.onSubmit();
    },

    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.searchFilters.keyword && this.searchFilters.keyword.length > 0) {
        filters.keyword = this.searchFilters.keyword;
      }

      if (this.searchFilters.serialNumber && this.searchFilters.serialNumber.length > 0) {
        filters.serialNumberKeyword = this.searchFilters.serialNumber;
      }

      filters.isEffective = this.searchFilters.isEffective;

      this.$api
        .post(
          "/api/products/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;

          this.items.forEach(product => {
            this.$api
              .post("/api/productSerialNumbers/search", { product: product })
              .then(({ data }) => {
                this.$set(product, "productSerialNumberTotal", data.totalElements);
              })
              .catch(() => {
                this.loading = false;
              });
          });
          this.total = data.totalElements;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initHeaders() {
      this.headers = [];

      this.headers.push({
        value: "productKey",
        text: this.$i18n.translate("Key"),
        sortable: true
      });
      this.headers.push({
        value: "name",
        text: this.$i18n.translate("Name"),
        sortable: true
      });
      this.headers.push({
        value: "productCategory.name",
        text: this.$i18n.translate("Category"),
        sortable: false
      });
      this.headers.push({
        value: "productSerialNumberTotal",
        text: this.$i18n.translate("Serial Number") + " " + this.$i18n.translate("Count"),
        sortable: false,
        align: "right"
      });

      let size = this.selectedProgram.programGroup.productCustomFields.length;
      for (let i = 1; i <= size; i++) {
        let productCustomField = this.selectedProgram.programGroup.productCustomFields[i - 1];
        if (productCustomField.name) {
          this.headers.push({
            value: "customFieldValue" + i,
            text: this.$i18n.translate(productCustomField.name),
            sortable: true
          });
        }
      }

      this.headers.push({
        value: "effectiveDate",
        text: this.$i18n.translate("Active"),
        align: "center",
        sortable: true
      });
      this.headers.push({
        value: "updatedDate",
        text: this.$i18n.translate("Last Updated"),
        sortable: true
      });
    }
  },
  mounted() {
    this.initHeaders();

    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: this.$i18n.translate("Clients"),
        to: { name: "clients" },
        exact: true
      },
      {
        text: this.selectedClient.name,
        to: {
          name: "client",
          params: { id: this.selectedClient.id },
          exact: true
        }
      },
      {
        text: this.$i18n.translate("Programs"),
        to: { name: "programs" },
        exact: true
      },
      {
        text: this.selectedProgram.name,
        to: {
          name: "program",
          params: { id: this.selectedProgram.id },
          exact: true
        }
      },
      { text: "Products" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),

    hasFilters() {
      if (this.searchFilters.keyword && this.searchFilters.keyword > 0) return true;
      if (this.searchFilters.serialNumber && this.searchFilters.serialNumber.length > 0) return true;

      return this.searchFilters.isEffective != undefined;
    }
  }
};
</script>
